import React, { useContext } from 'react'
import pageContextProvider from '@helpers/pageContextProvider'
import { Box, Flex } from 'theme-ui'
import { Stack, Main, PreFooter } from '@layout'
import PageTitle from '@components/PageTitle'
import Pagination from '@components/Pagination'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Categories from '@widgets/Categories'
import Tags from '@widgets/Tags'
import { useBlogCategories, useBlogTags } from '@helpers-blog'

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, `wrap`, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`]
  }
}

const Collection = ({ data: { posts, collectionInfo } }) => {
  const context = useContext(pageContextProvider)
  const categories = useBlogCategories()
  const tags = useBlogTags()

  return (
    <>
      <Seo title={`${collectionInfo.name} Franchises`} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header={`${collectionInfo.name} Franchises`}
          subheader={collectionInfo.title}
          running={collectionInfo.description}
          totalCount={posts.totalCount}
        />
      </Stack>
      <Divider />
      <Stack>
        <Main>
          {posts.nodes && (
            <CardList
              nodes={posts.nodes}
              variant={['horizontal-md', 'vertical']}
              columns={[1, 2, 3, 3]}
              omitCategory={
                context.pageContext &&
                context.pageContext.collectionType === 'category'
              }
            />
          )}
        </Main>
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination {...posts.pageInfo} {...collectionInfo} />
      </PreFooter>
      <Divider space={4} />
      <Stack effectProps={{ effect: false }}>
        <Flex sx={styles.grid}>
          <Box sx={styles.column}>
            <Categories categories={categories} variant='vertical' />
          </Box>
          <Box sx={styles.column}>
            <Tags tags={tags} />
          </Box>
        </Flex>
      </Stack>
    </>
  )
}
export default Collection
